import { memo, splitProps } from '../helpers.js';
import { createRecipe, mergeRecipes } from './create-recipe.js';

const popoverFn = /* @__PURE__ */ createRecipe('popover', {}, [])

const popoverVariantMap = {
  "placement": [
    "bottom-start",
    "bottom-end",
    "bottom",
    "top-start",
    "top-end",
    "top",
    "right-start",
    "right-end",
    "right",
    "left-start",
    "left-end",
    "left"
  ]
}

const popoverVariantKeys = Object.keys(popoverVariantMap)

export const popover = /* @__PURE__ */ Object.assign(memo(popoverFn.recipeFn), {
  __recipe__: true,
  __name__: 'popover',
  __getCompoundVariantCss__: popoverFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: popoverVariantKeys,
  variantMap: popoverVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, popoverVariantKeys)
  },
  getVariantProps: popoverFn.getVariantProps,
})